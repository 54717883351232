import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import Loading from "../components/Loading";
// image
import imgGo from "../assets/img/ic-go.svg";
import imgCopy from "../assets/img/ic-copy.svg";
import imgTick from "../assets/img/ic-tick.svg";
import imgLinkedin from "../assets/img/ic-linkedin.svg";
import imgPhone from "../assets/img/phone.png";
import imgKomNPlay from "../assets/img/komnplay.png";
// helpers
import chainAPI from "../helpers/ChainHelper";
import websiteApi from "../helpers/ApiHelper";
// constants
import communityData from "../constants/data/community.json";
import roadmapData from "../constants/data/roadmap.json";
import investData from "../constants/data/invest.json";
import teamData from "../constants/data/team.json";
import featureData from "../constants/data/feature.json";
import { SVGIcon } from "../helpers/SVGHelper";

function Home() {
	const carouselSetting = {
		itemsToShow: 1,
		pagination: false,
		itemPadding: [10],
		breakPoints: [
			{ width: 1, showArrows: false },
			{ width: 512, showArrows: false },
			{ width: 1024, showArrows: true },
		],
	};
	const eventCarouselSetting = {
		breakPoints: [
			{ width: 1, itemsToShow: 1, showArrows: false },
			{ width: 512, itemsToShow: 1, showArrows: false },
			{ width: 1024, itemsToShow: 3, showArrows: true },
		],
	};
	const awardRecognitionsCarouselSetting = {
		breakPoints: [
			{ width: 1, itemsToShow: 1, showArrows: false },
			{ width: 512, itemsToShow: 1, showArrows: false },
			{ width: 1024, itemsToShow: 3, showArrows: true },
		],
	};
	const exchangeCarouselSetting = {
		breakPoints: [
			{ width: 1, itemsToShow: 1, showArrows: false },
			{ width: 512, itemsToShow: 3, showArrows: false },
			{ width: 1024, itemsToShow: 6, showArrows: true },
		],
	};
	const testimonyCarouselSetting = {
		breakPoints: [
			{ width: 1, itemsToShow: 1, showArrows: false },
			{ width: 512, itemsToShow: 1, showArrows: false },
			{ width: 1024, itemsToShow: 3, showArrows: true },
		],
	};
	const initPeriod = [
		{ val: 30, apy: 0.03 },
		{ val: 60, apy: 0.05 },
		{ val: 90, apy: 0.07 },
		{ val: 180, apy: 0.09 },
		{ val: 365, apy: 0.12 },
		{ val: 730, apy: 0.15 },
	];
	const [isLoadingFeature, setLoadingFeature] = useState(true);
	const [kom, setKOM] = useState({});
	const [events, setEvents] = useState([]);
	const [partnerships, setPartnerships] = useState([]);
	const [portfolios, setPortfolios] = useState([]);
	const [exchanges, setExchanges] = useState([]);
	const [communityPartnerships, setCommunityPartnerships] = useState([]);
	const [newsMedias, setNewsMedias] = useState([]);
	const [awardRecognitions, setAwardRecognitions] = useState([]);
	const [testimonies, setTestimonies] = useState([]);
	const [newsMediaCurrently, setNewsMediaCurrently] = useState(0);
	const [copiedMATIC, setCopiedMATIC] = useState(false);
	const [copiedARB, setCopiedARB] = useState(false);
	const [treasury, setTreasury] = useState([]);
	const [features, setFeatures] = useState({});
	const [launchpadHeader, setLaunchpadHeader] = useState({});
	const [launchpadData, setLaunchpadData] = useState({});
	const [calculate, setCalculate] = useState({
		period: 0,
		purchase: 0,
		pricing: 0,
		earning: 0,
		private: 0,
		public: 0,
	});
	const currencyFormat = (value) => {
		return new Intl.NumberFormat("en-US").format(value);
	};
	const doCalculation = (valPeriod, valPurchase, valPrice) => {
		const periodStake = valPeriod ? valPeriod : 0;
		const purchaseToken = valPurchase ? valPurchase?.replace(/[^0-9]/g, "") : 0;
		const pricingToken = purchaseToken * valPrice;
		const earningToken = ((purchaseToken * initPeriod[periodStake].val) / 365) * initPeriod[periodStake].apy;
		// formula = purchase_token / total_voters_stake * total_raise * percentage_wo_wl
		const privateSale = (purchaseToken / 250000000) * 50000 * 0.6;
		const publicSale = (purchaseToken / 250000000) * 100000 * 0.6;
		setCalculate({
			period: periodStake,
			purchase: purchaseToken,
			pricing: pricingToken,
			earning: earningToken,
			private: privateSale,
			public: publicSale,
		});
	};
	const imgPolygon = () => {
		return (
			<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 38.4 33.5" xmlns="http://www.w3.org/2000/svg" className="inline w-5">
				<path
					d="M29,10.2c-0.7-0.4-1.6-0.4-2.4,0L21,13.5l-3.8,2.1l-5.5,3.3c-0.7,0.4-1.6,0.4-2.4,0L5,16.3
					c-0.7-0.4-1.2-1.2-1.2-2.1v-5c0-0.8,0.4-1.6,1.2-2.1l4.3-2.5c0.7-0.4,1.6-0.4,2.4,0L16,7.2c0.7,0.4,1.2,1.2,1.2,2.1v3.3l3.8-2.2V7
					c0-0.8-0.4-1.6-1.2-2.1l-8-4.7c-0.7-0.4-1.6-0.4-2.4,0L1.2,5C0.4,5.4,0,6.2,0,7v9.4c0,0.8,0.4,1.6,1.2,2.1l8.1,4.7
					c0.7,0.4,1.6,0.4,2.4,0l5.5-3.2l3.8-2.2l5.5-3.2c0.7-0.4,1.6-0.4,2.4,0l4.3,2.5c0.7,0.4,1.2,1.2,1.2,2.1v5c0,0.8-0.4,1.6-1.2,2.1
					L29,28.8c-0.7,0.4-1.6,0.4-2.4,0l-4.3-2.5c-0.7-0.4-1.2-1.2-1.2-2.1V21l-3.8,2.2v3.3c0,0.8,0.4, 1.6,1.2,2.1l8.1,4.7
					c0.7,0.4,1.6,0.4,2.4,0l8.1-4.7c0.7-0.4,1.2-1.2,1.2-2.1V17c0-0.8-0.4-1.6-1.2-2.1L29,10.2z"
				/>
			</svg>
		);
	};
	const imgArbitrum = () => {
		return (
			<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 2500 2500" xmlns="http://www.w3.org/2000/svg" className="inline w-5">
				<rect fill="none" width="2500" height="2500"></rect>
				<path
					fill="#213147"
					d="M226,760v980c0,63,33,120,88,152l849,490c54,31,121,31,175,0l849-490c54-31,88-89,88-152V760
					c0-63-33-120-88-152l-849-490c-54-31-121-31-175,0L314,608c-54,31-87,89-87,152H226z"
				/>
				<path fill="#12AAFF" d="M1435,1440l-121,332c-3,9-3,19,0,29l208,571l241-139l-289-793C1467,1422,1442,1422,1435,1440z" />
				<path fill="#12AAFF" d="M1678,882c-7-18-32-18-39,0l-121,332c-3,9-3,19,0,29l341,935l241-139L1678,883V882z" />
				<path
					fill="#9DCCED"
					d="M1250,155c6,0,12,2,17,5l918,530c11,6,17,18,17,30v1060c0,12-7,24-17,30l-918,530c-5,3-11,5-17,5
					s-12-2-17-5l-918-530c-11-6-17-18-17-30V719c0-12,7-24,17-30l918-530c5-3,11-5,17-5l0,0V155z M1250,0c-33,0-65,8-95,25L237,555
					c-59,34-95,96-95,164v1060c0,68,36,130,95,164l918,530c29,17,62,25,95,25s65-8,95-25l918-530c59-34,95-96,95-164V719
					c0-68-36-130-95-164L1344,25c-29-17-62-25-95-25l0,0H1250z"
				/>
				<polygon fill="#213147" points="642,2179 727,1947 897,2088 738,2234" />
				<path fill="#FFFFFF" d="M1172,644H939c-17,0-33,11-39,27L401,2039l241,139l550-1507c5-14-5-28-19-28L1172,644z" />
				<path fill="#FFFFFF" d="M1580,644h-233c-17,0-33,11-39,27L738,2233l241,139l620-1701c5-14-5-28-19-28V644z" />
			</svg>
		);
	};
	const copyMATIC = (value) => {
		navigator.clipboard.writeText(value).then(() => {
			setCopiedMATIC(true);
			setTimeout(() => setCopiedMATIC(false), 2500);
		});
	};
	const copyARB = (value) => {
		navigator.clipboard.writeText(value).then(() => {
			setCopiedARB(true);
			setTimeout(() => setCopiedARB(false), 2500);
		});
	};
	const testimoniIcon = (value) => {
		switch (value) {
			case "LinkedIn":
				return "https://kommunitas.net/assets/social/linkedin.svg";
			case "Twitter":
				return "https://kommunitas.net/assets/social/twitter.svg";
			case "Telegram":
				return "https://kommunitas.net/assets/social/telegram.svg";
			default:
				return "";
		}
	};

	useEffect(() => {
		websiteApi.getComparison().then((response) => {
			if (response.data.status === "success") {
				setLaunchpadHeader(response.data.result.header);
				setLaunchpadData(response.data.result.data);
			}
		});
		websiteApi.getPortfolios().then((response) => {
			if (response.data.status === "success") {
				let result = response.data.result,
					results = [],
					size = 24;
				while (result.length > 0) results.push(result.splice(0, size));
				setPortfolios(results);
			}
		});
		websiteApi.getPartnerships().then((response) => {
			if (response.data.status === "success") {
				let result = response.data.result,
					results = [],
					size = 24;
				while (result.length > 0) results.push(result.splice(0, size));
				setPartnerships(results);
			}
		});
		websiteApi.getCommunityPartnerships().then((response) => {
			if (response.data.status === "success") {
				let result = response.data.result,
					results = [],
					size = 24;
				while (result.length > 0) results.push(result.splice(0, size));
				setCommunityPartnerships(results);
			}
		});
		websiteApi.getStatistic().then((response) => {
			if (response.data.status === "success") setKOM(response.data.result);
			doCalculation("0", "500000", response.data.result.value);
		});
		websiteApi.getNewsMedias().then((response) => {
			if (response.data.status === "success") {
				const result = response.data.result;
				setNewsMedias(result);
				setInterval(() => {
					setNewsMediaCurrently((currentVal) => (currentVal + 1 < result.length ? currentVal + 1 : 0));
				}, 5000);
			}
		});
		websiteApi.getAwardRecognitions().then((response) => {
			if (response.data.status === "success") setAwardRecognitions(response.data.result);
		});
		websiteApi.getEvents().then((response) => {
			if (response.data.status === "success") setEvents(response.data.result);
		});
		websiteApi.getExchanges().then((response) => {
			if (response.data.status === "success") setExchanges(response.data.result);
		});
		websiteApi.getTestimony().then((response) => {
			if (response.data.status === "success") setTestimonies(response.data.result);
		});
		websiteApi.getTreasury().then((response) => {
			if (response.data.status === "success") setTreasury(response.data.result);
		});
		websiteApi.getFeature().then((response) => {
			if (response.data.status === "success") setFeatures(response.data.result);
			setLoadingFeature(false);
		});
		document.title = `Web 3.0 Crowdfunding Ecosystem Platform | Kommunitas`;
		document.querySelector('meta[name="description"]').setAttribute("content", "Kommunitas is a decentralized crowdfunding ecosystem for web3.0 projects.");
		const canonicalLink = document.querySelector("link[rel='canonical']") || document.createElement("link");
		canonicalLink.rel = "canonical";
		canonicalLink.href = window.location.href;
		if (!document.querySelector("link[rel='canonical']")) {
			document.head.appendChild(canonicalLink);
		}
	}, []);

	useEffect(() => {
		if (events && partnerships) {
			const params = new URLSearchParams(window.location.search);
			const section = params.get("section");
			if (section && document.getElementById(section)) window.scrollTo(0, document.getElementById(section).offsetTop);
		}
	}, [events, partnerships]);

	return (
		<main>
			<section id="introduction" className="section-bg">
				<div className="container mx-auto my-16">
					<div className="mb-8 items-center grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
						<div className="overflow-hidden">
							<h1>
								<div className="text-lg font-bold">Web 3.0 Blockchain Crowdfunding Ecosystem</div>
								<div className="baumans text-5xl lg:text-7xl">Kommunitas</div>
							</h1>
							<p className="mt-2 mb-8 text-lg">
								Kommunitas is a decentralized crowdfunding ecosystem for web3.0 projects. Some might refer to it as a "launchpad" or "IDO Platform" but Kommunitas is aiming to build
								not only a platform, but more to an ecosystem.
							</p>
							<h2 className="mb-2 font-bold">Featured Projects' ATH</h2>
							{isLoadingFeature ? (
								<div className="container mx-auto mb-12 text-left">
									<Loading message={"Fetching data from server ..."} />
								</div>
							) : (
								<div className="marquee">
									{features.map((val, idx) => {
										return (
											<a key={idx} href={val.link} rel="noreferrer">
												<div className="item inline-block mx-4 cursor-pointer">
													<div className="flex items-center">
														<img src={val.image} className="mr-4" loading="lazy" alt="logo" />
														<div className="mx-auto">
															<div className="text-sm font-bold">{val.name}</div>
															<div className="txt-secondary text-xl font-bold">{`${val.ath}x`}</div>
														</div>
													</div>
												</div>
											</a>
										);
									})}
								</div>
							)}
						</div>
						<div className="video-container">
							<iframe
								src="https://youtube.com/embed/2ZRHAnOHrf8"
								sandbox="allow-scripts allow-same-origin allow-presentation"
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					</div>
					<div className="mt-8 gap-8 grid grid-cols-1 lg:grid-cols-2">
						<div>
							<div className="mb-4 font-bold text-center text-3xl lg:text-left lg:text-4xl">KOM POL Mainnet</div>
							<div>
								<div className="xl:flex xl:items-center">
									<button className="button web3js mb-2 w-full lg:w-auto" onClick={chainAPI.setChainPolygon}>
										<div className="xl:flex xl:items-center">
											<div className="ic-network inline mr-2">{imgPolygon()}</div>
											<span className="inline">Add POL</span>
										</div>
									</button>
									<button className="button web3js mb-2 w-full lg:w-auto lg:mx-4" onClick={chainAPI.setTokenKOMMATIC}>
										<div className="ic-network inline mr-2">{imgPolygon()}</div>
										<span className="inline">Add KOM (POL)</span>
									</button>
									<button className="button web3js mb-2 w-full lg:w-auto" onClick={chainAPI.setTokenKOMVMATIC}>
										<div className="ic-network inline mr-2">{imgPolygon()}</div>
										<span className="inline">Add KOMV (POL)</span>
									</button>
								</div>
							</div>
							<div className="mt-2 mb-4">
								<button className="button web3js relative w-full flex justify-between items-center" onClick={() => copyMATIC(chainAPI.addressKOMMATIC)}>
									<div className="flex items-center">
										<div className="ic-network inline mr-2">{imgPolygon()}</div>
										<div className="address inline">{chainAPI.addressKOMMATIC}</div>
										<img src={imgCopy} className="ml-auto ic-copy-address" alt="copy" loading="lazy" />
									</div>
									{copiedMATIC && <div className="text-xs absolute right-1 bottom-0 lg:right-14 lg:top-5">Copied!</div>}
								</button>
							</div>
						</div>
						<div>
							<div className="mb-4 font-bold text-center text-3xl lg:text-left lg:text-4xl">KOM Arbitrum One</div>
							<div>
								<div className="xl:flex xl:items-center">
									<button className="button web3js mb-2 w-full lg:w-auto" onClick={chainAPI.setChainArbitrum}>
										<div className="xl:flex xl:items-center">
											<div className="ic-network inline mr-2">{imgArbitrum()}</div>
											<span className="inline">Add ARBITRUM</span>
										</div>
									</button>
									<button className="button web3js mb-2 w-full lg:w-auto lg:mx-4" onClick={chainAPI.setTokenKOMARB}>
										<div className="ic-network inline mr-2">{imgArbitrum()}</div>
										<span className="inline">Add KOM (ARB)</span>
									</button>
									<button className="button web3js mb-2 w-full lg:w-auto" onClick={chainAPI.setTokenKOMVARB}>
										<div className="ic-network inline mr-2">{imgArbitrum()}</div>
										<span className="inline">Add KOMV (ARB)</span>
									</button>
								</div>
							</div>
							<div className="mt-2 mb-4">
								<button className="button web3js relative w-full flex justify-between items-center" onClick={() => copyARB(chainAPI.addressKOMARB)}>
									<div className="flex items-center">
										<div className="ic-network inline mr-2">{imgArbitrum()}</div>
										<div className="address inline">{chainAPI.addressKOMARB}</div>
										<img src={imgCopy} className="ml-auto ic-copy-address" alt="copy" loading="lazy" />
									</div>
									{copiedARB && <div className="text-xs absolute right-1 bottom-0 lg:right-14 lg:top-5">Copied!</div>}
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="calculate" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">Calculate your $KOMvestment</h2>
					<div className="kom-calculator items-center grid gap-4 grid-cols-1 lg:grid-cols-2 lg:gap-16">
						<div>
							<div className="grid gap-4 grid-cols-1 lg:grid-cols-2 lg:gap-8">
								<div>
									<label htmlFor="investment_value" className="label">
										How Much KOM?
									</label>
									<input
										type="text"
										id="investment_value"
										maxLength="12"
										className="input w-full py-2 px-4"
										value={currencyFormat(calculate.purchase)}
										onChange={(e) => doCalculation(calculate.period, e.target.value, kom?.value)}
									/>
								</div>
								<div>
									<label className="label">You will need</label>
									<div className="value w-full py-2 px-4">{"$" + currencyFormat(calculate.pricing)}</div>
								</div>
							</div>
							<hr className="divider" />
							<div className="grid gap-4 grid-cols-1 lg:grid-cols-2 lg:gap-8">
								<div>
									<label htmlFor="investment_period" className="label">
										Enter days to be staked
									</label>
									<select id="investment_period" className="input w-full py-2 px-4" onChange={(e) => doCalculation(e.target.value, calculate.purchase, kom?.value)}>
										{initPeriod.map((value, index) => (
											<option key={index} value={index}>
												{`${value.val} days`}
											</option>
										))}
									</select>
								</div>
								<div>
									<div className="label">You will earn</div>
									<div className="value w-full py-2 px-4">{`${currencyFormat(calculate.earning)} KOM`}</div>
								</div>
							</div>
							<p className="mt-4 text-sm">
								<span className="mr-1">Get a guaranteed allocation in all sales while earning KOM rewards by staking your KOM at</span>
								<a href="https://staking.kommunitas.net/" target="_blank" rel="noreferrer" className="underline">
									https://staking.kommunitas.net/
								</a>
							</p>
						</div>
						<div>
							<div className="label text-xl mb-2 mt-8">If you Vote and Participate in KOM Web3.0 Crowdfunding platform,</div>
							<div className="mb-4">
								<div className="label">
									{calculate.purchase >= 500000 ? (
										<>
											You will be eligible to purchase approximately
											<span className="text-white font-bold px-2">{"$" + currencyFormat(calculate.private)}</span>
											in Private Round Sale (assuming total raise = $50,000)
										</>
									) : (
										"You won't be eligible to purchase in Private Round Sale."
									)}
								</div>
								<div className="label">
									You will be eligible to purchase approximately
									<span className="text-white font-bold px-2">{"$" + currencyFormat(calculate.public)}</span>
									in Public Round Sale (assuming total raise = $100,000)
								</div>
							</div>
							<div className="label text-sm italic">
								<span className="pr-1">*Private Sale is only for those who stake minimum</span>
								<span className="text-white font-bold">500,000 KOM</span>
								<span className="px-1">and Private Partners will enjoy a</span>
								<span className="text-white font-bold">30%</span>
								<span className="pl-1">Revenue Sharing from all sales.</span>
								<span className="pl-1">If you stake for </span>
								<span className="text-white font-bold">730 days</span>
								<span className="px-1">, you will be eligible to get</span>
								<span className="text-white font-bold">free tokens airdrop from projects</span>
								<span>.</span>
							</div>
							{treasury?.map((val, key) => {
								return (
									<div key={key} className="label text-sm italic">
										<div>**{val.asset} Treasury</div>
										<a href={val.link} target="_blank" rel="noreferrer" className="underline">
											{val.address}
										</a>
										<span className="mx-1">=</span>
										<span className="text-white font-bold">{val.value}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</section>
			<section id="comparison" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">How KOM is better than other Web3 Crowdfunding Ecosystem?</h2>
					<div className="mb-10 relative">
						<div className="kom-comparison-selected">&nbsp;</div>
						<div className="relative">
							{Object.entries(launchpadData).map(
								(val, key) =>
									key > 0 && (
										<div key={key} className="grid grid-cols-4 items-stretch kom-comparison">
											<div className="header py-4 break-word font-bold text-xs sm:text-sm md:text-md lg:text-lg md:px-2">
												<div className="flex items-center">
													<div className="mr-1">{typeof launchpadHeader[val[0]] === "string" ? launchpadHeader[val[0]] : launchpadHeader[val[0]].text}</div>
													{typeof launchpadHeader[val[0]] !== "string" && (
														<a href={launchpadHeader[val[0]]?.link} className="underline" target="_blank" rel="noreferrer">
															<div className="fill-current h-4 w-4">{SVGIcon("iconinfo")}</div>
														</a>
													)}
												</div>
											</div>
											{val[1].map((valData, keyData) => (
												<div key={keyData} className={"data py-4 break-word text-center text-xs sm:text-sm md:text-md lg:text-lg" + (key === 1 ? " font-bold text-white" : "")}>
													{typeof valData === "string"
														? valData
														: valData.map((valDataItem, keyDataItem) => (
																<div key={keyDataItem}>
																	<div className="inline-block mr-1">{typeof valDataItem === "string" ? valDataItem : valDataItem?.text}</div>
																	{typeof valDataItem !== "string" && (
																		<div className="inline-block">
																			<a href={valDataItem?.link} className="underline" target="_blank" rel="noreferrer">
																				<div className="fill-current h-4 w-4">{SVGIcon("iconinfo")}</div>
																			</a>
																		</div>
																	)}
																</div>
														  ))}
												</div>
											))}
										</div>
									)
							)}
						</div>
					</div>
					<h2 className="mt-16 mb-4 text-left text-3xl font-bold">Kommunitas Features: Web 3.0 Crowdfunding Platform</h2>
					<div className="mb-4 grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
						{featureData.map((val, idx) => (
							<div key={idx} className="button">
								<div className="flex items-center justify-between">
									<h3 className="mr-8 font-bold">{val.name}</h3>
									<img src={val.image} className="ml-2" alt="matic" loading="lazy" />
								</div>
								<p className="my-4">{val.desc}</p>
								{typeof val.link !== "undefined" && (
									<a href={val.link} className="underline" target="_blank" rel="noreferrer">
										Read more
									</a>
								)}
							</div>
						))}
					</div>
				</div>
			</section>
			<section id="events" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">
						<a href="https://docs.kommunitas.net/event" target="_blank" rel="noreferrer">
							Events
						</a>
					</h2>
					{events.length > 0 && (
						<Carousel {...carouselSetting} {...eventCarouselSetting}>
							{events.map((val, idx) => {
								return (
									<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
										<img src={val.foto} alt={val.nama} className="rounded-md xl:rounded-xl" loading="lazy" />
									</a>
								);
							})}
						</Carousel>
					)}
					{events.length === 0 && <div className="text-center text-lg font-bold italic">No events data found</div>}
				</div>
			</section>
			<section id="roadmap" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">
						<a href="https://docs.kommunitas.net/roadmap" target="_blank" rel="noreferrer">
							Roadmap
						</a>
					</h2>
					<Carousel {...carouselSetting}>
						{roadmapData.map((val, idx1) => {
							return (
								<div key={idx1} className="w-full grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-4 md:gap-8">
									{val.roadmaps.map((roadmap, idx2) => {
										return (
											<div key={idx2} className="roadmap relative">
												<div className="roadmap-title md:absolute">{`${roadmap.title} ${val.year}`}</div>
												<div className="roadmap-content ml-12 md:ml-16 md:pl-2">
													{roadmap.data.map((data, idx3) => (
														<div key={idx3} className="mb-2">
															<div className="roadmap-tick flex items-center">{data.status && <img src={imgTick} className="mx-auto" alt="tick" loading="lazy" />}</div>
															<div className="roadmap-name ml-8 text-sm">{data.name}</div>
														</div>
													))}
												</div>
											</div>
										);
									})}
								</div>
							);
						})}
					</Carousel>
				</div>
			</section>
			<section id="incubator" className="section-bg">
				<div className="container mx-auto my-16">
					<div className="w-full mb-12">
						<h2 className="mb-10 text-left text-3xl font-bold">Kommunitas Incubator Fund - Best Polygon Launchpad</h2>
						<p className="mb-8 text-justify">
							As a leading Web 3.0 crowdfunding ecosystem, our goal is to allow project teams to focus on developing and building their idea and product, while our team handles the
							marketing, exposure and acquiring the initial user base. Our Incubator Fund has successfully raised over $2 Million dollars from its Private Partners and we are looking to
							invest in strong teams with a unique and innovative vision in the cryptosphere, especially those in the field of Gaming, NFT, Metaverse, and DeFi with Unique Selling
							Points. Kommunitas welcomes any Web 3.0 projects of any fund-raising stage, from seed round to public round. Apply now!
						</p>
						<a className="button w-full mr-2 lg:w-auto" href="https://forms.gle/FV82SrPfskRR5bsU9" target="_blank" rel="noreferrer">
							Apply now!
						</a>
						<a
							className="button w-full ml-2 lg:w-auto"
							href="https://medium.com/@kommunitas/kommunitas-establishes-a-2-million-incubator-fund-to-support-promising-projects-in-the-crypto-98de726c900"
							target="_blank"
							rel="noreferrer"
						>
							Read more
						</a>
					</div>
					<div className="w-full">
						<h2 className="mb-10 text-left text-3xl font-bold">Portfolio</h2>
						{portfolios.length > 0 && (
							<Carousel {...carouselSetting}>
								{portfolios.map((portfolio, idx) => {
									return (
										<div key={idx} className="portfolio w-full grid gap-8 grid-cols-4 md:grid-cols-6 lg:grid-cols-8 place-items-center">
											{portfolio.map((val, idx) => {
												return (
													<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
														<img src={val.foto} alt={val.nama} loading="lazy" />
													</a>
												);
											})}
										</div>
									);
								})}
							</Carousel>
						)}
						{portfolios.length === 0 && <div className="text-center text-lg font-bold italic">No portfolios data found</div>}
					</div>
				</div>
			</section>
			<section id="invest" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">Invest in $KOM</h2>
					<p className="mb-12">
						Investing in cryptocurrency is all about Timing and Demand behind the project. Everybody dreams of turning time back to the 2010s and investing in Bitcoin before it broke a
						Dollar. Kommunitas is committed to integrate more functionality in our token, thus increasing the demand for our token. Holding $KOM will be like having the opportunity to buy
						bitcoin in its early days, as we shape the future of Web 3.0.
					</p>
					<div className="w-full">
						<div className="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
							{investData.map((val, idx) => (
								<div key={idx} className="button">
									<div className="py-2">
										<h3 className="font-bold">{val.name}</h3>
										<p className="mt-4">{val.desc}</p>
										{typeof val.link !== "undefined" && (
											<div className="pt-2">
												<a href={val.link.url} className="underline" target="_blank" rel="noreferrer">
													{val.link.name}
												</a>
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<section id="pricefeed" className="section-bg">
				<div className="container mx-auto my-16">
					<div className="mb-12 text-center grid grid-cols-2 gap-4 lg:grid-cols-4">
						<div>
							<kom-price-value>{kom?.price}</kom-price-value>
							<kom-price-label>Price</kom-price-label>
						</div>
						<div>
							<kom-holder-value>{kom?.holder}</kom-holder-value>
							<kom-holder-label>Holders</kom-holder-label>
						</div>
						<div>
							<kom-circulating-value>{kom?.circulating}</kom-circulating-value>
							<kom-circulating-label>Circulating Supply</kom-circulating-label>
						</div>
						<div>
							<kom-marketcap-value>{kom?.marketcap}</kom-marketcap-value>
							<kom-marketcap-label>Market Cap</kom-marketcap-label>
						</div>
					</div>
					<coingecko-coin-ticker-widget currency="usd" coin-id="kommunitas" locale="en" background-color="#ffffff"></coingecko-coin-ticker-widget>
				</div>
			</section>
			<section id="exchanges" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">
						<a href="https://docs.kommunitas.net/exchanges-1" target="_blank" rel="noreferrer">
							Exchange
						</a>
					</h2>
					{exchanges.length > 0 && (
						<Carousel {...carouselSetting} {...exchangeCarouselSetting}>
							{exchanges.map((val, idx) => {
								return (
									<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
										<img src={val.foto} alt={val.nama} className="rounded-md xl:rounded-xl" loading="lazy" />
									</a>
								);
							})}
						</Carousel>
					)}
					{exchanges.length === 0 && <div className="text-center text-lg font-bold italic">No exchanges data found</div>}
				</div>
			</section>
			<section id="komnplay" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">Don’t feel like investing in Web 3.0 Projects?</h2>
					<p className="mb-8 text-center text-xl">How about having fun with Captain KOM and earn some KOM?</p>
					<div className="mx-auto max-width-850">
						<div className="mb-4">
							<a href="https://play.kommunitas.net" target="_blank" rel="noreferrer">
								<img src={imgKomNPlay} alt="KOMnPlay Logo" className="mx-auto" loading="lazy" />
							</a>
						</div>
						<div className="video-container komnplay">
							<iframe
								src="https://youtube.com/embed/eOzWwojJMWY"
								sandbox="allow-scripts allow-same-origin allow-presentation"
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					</div>
				</div>
			</section>
			<section id="partnerships" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">
						<a href="https://docs.kommunitas.net/partnership-1" target="_blank" rel="noreferrer">
							Partnerships
						</a>
					</h2>
					<p className="mb-8">
						Kommunitas is Open to Partnerships with all entities in the Cryptosphere! Be it Crypto Projects, Advisors, Other Launchpads, Capital Funds, Private Funds, Auditors, Centralized
						Exchanges and Decentralized Exchanges, we invite you to explore collaboration opportunities with us. Together, we can unlock the full potential of Web 3.0 crowdfunding. Don't
						miss out on this opportunity and consider applying now!
					</p>
					<a className="button" href="https://forms.gle/YNKciGfqZv6H4VeRA" target="_blank" rel="noreferrer">
						Apply now!
					</a>
					{partnerships.length > 0 && (
						<Carousel {...carouselSetting}>
							{partnerships.map((partnership, key) => (
								<div key={key} className="partnership w-full mt-12 grid gap-8 grid-cols-4 md:grid-cols-6 lg:grid-cols-8 place-items-center">
									{partnership.map((val, idx) => {
										return (
											<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
												<img src={val.foto} alt={val.nama} className="rounded-md xl:rounded-xl" loading="lazy" />
											</a>
										);
									})}
								</div>
							))}
						</Carousel>
					)}
					{partnerships.length === 0 && <div className="text-center text-lg font-bold italic">No partnerships data found</div>}
				</div>
			</section>
			<section id="communitypartnerships" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">
						<a href="https://docs.kommunitas.net/partnership-1/community-partner" target="_blank" rel="noreferrer">
							Community Partners
						</a>
					</h2>
					<p className="mb-8">
						Kommunitas is also on the lookout for partnerships with regional communities revolving around Cryptocurrency. If you are a crypto community’s telegram group owner with over
						2,000 active members, do consider joining us as an Official KOM Community Partner.
					</p>
					<a className="button" href="https://forms.gle/SaAJ2NoH8qHsqCyd8" target="_blank" rel="noreferrer">
						Apply now!
					</a>
					{communityPartnerships.length > 0 && (
						<Carousel {...carouselSetting}>
							{communityPartnerships.map((communityPartnership, key) => (
								<div key={key} className="community-partner w-full mt-12 grid gap-8 grid-cols-4 md:grid-cols-6 lg:grid-cols-8 place-items-center">
									{communityPartnership.map((val, idx) => {
										return (
											<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
												<img src={val.foto} alt={val.nama} className="rounded-md xl:rounded-xl" loading="lazy" />
											</a>
										);
									})}
								</div>
							))}
						</Carousel>
					)}
					{communityPartnerships.length === 0 && <div className="text-center text-lg font-bold italic">No community partnerships data found</div>}
				</div>
			</section>
			<section id="newsmedias" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">
						<a href="https://docs.kommunitas.net/news-and-media" target="_blank" rel="noreferrer">
							As seen on
						</a>
					</h2>
					{newsMedias.length > 0 && (
						<div className="w-full max-width-850 mx-auto">
							<div className="as-seen-on">
								<div className="flex justify-between items-center">
									<img src={newsMedias[newsMediaCurrently]?.preview || imgPhone} className="hidden md:block" alt="phone" />
									<div className="newsmedia grid gap-4 grid-cols-4 md:ml-8 md:grid-cols-5 place-items-center">
										{newsMedias.map((val, idx) => (
											<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
												<img src={val.foto} alt={val.nama} className={idx === newsMediaCurrently ? "active" : ""} loading="lazy" />
											</a>
										))}
									</div>
								</div>
							</div>
						</div>
					)}
					{newsMedias.length === 0 && <div className="text-center text-lg font-bold italic">No news & media data found</div>}
				</div>
			</section>
			<section id="awardrecognitions" className="section-bg">
				<div className="container mx-auto my-16">
					<h2 className="mb-10 text-left text-3xl font-bold">Awards & Recognitions in the Web 3.0 Field</h2>
					{awardRecognitions.length > 0 && (
						<Carousel {...carouselSetting} {...awardRecognitionsCarouselSetting}>
							{awardRecognitions.map((val, idx) => {
								return (
									<a key={idx} href={val.link || "#"} target={val.link && "_blank"} rel={val.link && "noreferrer"}>
										<img src={val.foto} alt={val.nama} className="rounded-md xl:rounded-xl" loading="lazy" />
										<div className="mt-4 text-center font-bold italic">{val.nama}</div>
									</a>
								);
							})}
						</Carousel>
					)}
					{awardRecognitions.length === 0 && <div className="text-center text-lg font-bold italic">No awards & recognitions data found</div>}
				</div>
			</section>
			<section id="communities" className="section-bg">
				<div className="container mx-auto my-12">
					<h2 className="mb-10 text-left text-3xl font-bold">Which language do you speak?</h2>
					<div className="w-full max-width-850 mx-auto">
						<div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
							{communityData.map((val, idx) => (
								<div key={idx} className="button">
									<a href={val.link} target="_blank" rel="noreferrer">
										<div className="flex justify-between items-center">
											<div className="flex justify-between items-center">
												<img src={val.image} alt="community-flag" className="w-3 lg:w-6" loading="lazy" />
												<div className="ml-2 text-sm lg:text-md">{val.name}</div>
											</div>
											<img src={imgGo} className="w-3 lg:w-6" alt="go" loading="lazy" />
										</div>
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<section id="testimonies" className="section-bg">
				<div className="container mx-auto my-12">
					<div className="w-full mx-auto">
						<h2 className="mb-10 text-left text-3xl font-bold">What do they say about us</h2>
						{testimonies.length > 0 && (
							<Carousel {...carouselSetting} {...testimonyCarouselSetting}>
								{testimonies.map((val, idx) => {
									return (
										<div key={idx} className="testimony-box button">
											<div className="flex items-center">
												<img src={val.foto} alt={val.nama} className="testimony-img" loading="lazy" />
												<div className="ml-4">
													<div className="mb-1 text-xl font-bold">{val.nama}</div>
													<div className="flex items-center">
														{val.link && val.social && (
															<a href={val.link} target="_blank" rel="noreferrer">
																<img src={testimoniIcon(val.social)} className="w-4" alt="social" loading="lazy" />
															</a>
														)}
														<span className="ml-1 text-sm italic">{val.person}</span>
													</div>
												</div>
											</div>
											<p className="mt-4 testimony-desc">{val.desc}</p>
										</div>
									);
								})}
							</Carousel>
						)}
						{testimonies.length === 0 && <div className="text-center text-lg font-bold italic">No testimonies data found</div>}
					</div>
				</div>
			</section>
			<section id="teams" className="section-bg">
				<div className="container mx-auto my-12">
					<h2 className="mb-10 text-left text-3xl font-bold">Some of us who make this happen</h2>
					<div className="w-full mx-auto">
						<div className="grid grid-cols-2 lg:grid-cols-5">
							{teamData.map((val, idx) => (
								<div key={idx} className="mx-auto p-4">
									<img src={val.image} alt={val.name} loading="lazy" />
									<div className="relative">
										<div className="mr-4 text-lg font-bold">{val.name}</div>
										<a href={val.linkedin} className="absolute top-0 right-0" target="_blank" rel="noreferrer">
											<img src={imgLinkedin} className="w-6 lg:w-8" alt="linkedin" loading="lazy" />
										</a>
									</div>
									<div className="text-sm">{val.position}</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
			<section id="steps" className="section-bg">
				<div className="container mx-auto my-12">
					<h2 className="mb-10 text-center text-3xl font-bold">
						Are you ready to <span className="txt-ternary">2x</span> your investment in no time?
					</h2>
					<div className="connected-steps mx-auto mt-6 lg:mt-12">
						<a href="https://docs.kommunitas.net/exchanges-1" target="_blank" rel="noreferrer" className="step">
							<div>1</div>
							<div>Buy KOM</div>
						</a>
						<div className="connector"></div>
						<a href="https://staking.kommunitas.net" target="_blank" rel="noreferrer" className="step">
							<div>2</div>
							<div>Stake KOM</div>
						</a>
						<div className="connector"></div>
						<a href="https://docs.kommunitas.net/faqs/launchpad-faq" target="_blank" rel="noreferrer" className="step">
							<div>3</div>
							<div>Participate</div>
						</a>
						<div className="connector"></div>
						<a href="https://calendar.kommunitas.net" target="_blank" rel="noreferrer" className="step">
							<div>4</div>
							<div>Subscribe</div>
						</a>
					</div>
				</div>
			</section>
		</main>
	);
}

export default Home;
