import Web3 from "web3";

const addressKOMMATIC = "0xC004e2318722EA2b15499D6375905d75Ee5390B8";
const addressKOMARB = "0xA58663FAEF461761e44066ea26c1FCddF2927B80";
const addressKOMVMATIC = "0xE1bB02b367173ac31077a8c443802f75CC9aCCb6";
const addressKOMVARB = "0xd0F7eC675F7D29BCf58FB1ea793CbA42644d05C4";

const getNetwork = () => {
	const web3 = new Web3(window.ethereum);
	return web3.eth.getChainId();
};

const setChainPolygon = () => {
	try {
		window.ethereum.request({
			method: "wallet_addEthereumChain",
			params: [
				{
					chainId: "0x89",
					chainName: "Matic Mainnet",
					nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
					rpcUrls: ["https://polygon-rpc.com"],
					blockExplorerUrls: ["https://polygonscan.com"],
				},
			],
		});
	} catch (error) {
		alert(error);
	}
};

const setChainArbitrum = () => {
	try {
		window.ethereum.request({
			method: "wallet_addEthereumChain",
			params: [
				{
					chainId: "0xA4B1",
					chainName: "Arbitrum One",
					nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
					rpcUrls: ["https://arb1.arbitrum.io/rpc"],
					blockExplorerUrls: ["https://arbiscan.io"],
				},
			],
		});
	} catch (error) {
		alert(error);
	}
};

const setTokenKOMMATIC = async () => {
	try {
		getNetwork().then((value) => {
			if (Number(value) === 137) {
				window.ethereum
					.request({
						method: "wallet_watchAsset",
						params: {
							type: "ERC20",
							options: {
								address: addressKOMMATIC,
								symbol: "KOM",
								decimals: 8,
								image: "https://kommunitas.net/assets/token-logo.png",
							},
						},
					})
					.then((val) => {
						console.log(val);
					})
					.catch((val) => {
						alert(val?.message);
					});
			} else {
				alert("Please make sure you are in matic chain by clicking 'Add MATIC' button");
			}
		});
	} catch (error) {
		alert(error);
	}
};

const setTokenKOMVMATIC = async () => {
	try {
		getNetwork().then((value) => {
			if (Number(value) === 137) {
				window.ethereum
					.request({
						method: "wallet_watchAsset",
						params: {
							type: "ERC20",
							options: {
								address: addressKOMVMATIC,
								symbol: "KOMV",
								decimals: 0,
								image: "https://kommunitas.net/assets/token-logo.png",
							},
						},
					})
					.then((val) => {
						console.log(val);
					})
					.catch((val) => {
						alert(val?.message);
					});
			} else {
				alert("Please make sure you are in matic chain by clicking 'Add MATIC' button");
			}
		});
	} catch (error) {
		alert(error);
	}
};

const setTokenKOMARB = async () => {
	try {
		getNetwork().then((value) => {
			if (Number(value) === 42161) {
				window.ethereum
					.request({
						method: "wallet_watchAsset",
						params: {
							type: "ERC20",
							options: {
								address: addressKOMARB,
								symbol: "KOM",
								decimals: 8,
								image: "https://kommunitas.net/assets/token-logo.png",
							},
						},
					})
					.then((val) => {
						console.log(val);
					})
					.catch((val) => {
						alert(val?.message);
					});
			} else {
				alert("Please make sure you are in arbitrum chain by clicking 'Add ARBITRUM' button");
			}
		});
	} catch (error) {
		alert(error);
	}
};

const setTokenKOMVARB = async () => {
	try {
		getNetwork().then((value) => {
			if (Number(value) === 42161) {
				window.ethereum
					.request({
						method: "wallet_watchAsset",
						params: {
							type: "ERC20",
							options: {
								address: addressKOMVARB,
								symbol: "KOMV",
								decimals: 0,
								image: "https://kommunitas.net/assets/token-logo.png",
							},
						},
					})
					.then((val) => {
						console.log(val);
					})
					.catch((val) => {
						alert(val?.message);
					});
			} else {
				alert("Please make sure you are in arbitrum chain by clicking 'Add ARBITRUM' button");
			}
		});
	} catch (error) {
		alert(error);
	}
};

const chainAPI = {
	addressKOMMATIC,
	addressKOMVMATIC,
	addressKOMARB,
	addressKOMVARB,
	setChainPolygon,
	setChainArbitrum,
	setTokenKOMMATIC,
	setTokenKOMVMATIC,
	setTokenKOMARB,
	setTokenKOMVARB,
};

export default chainAPI;
