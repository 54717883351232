import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import websiteApi from "../helpers/ApiHelper";
import Loading from "./Loading";

function BlogTag({ selected }) {
	const navigate = useNavigate();
	const [isLoadingTags, setLoadingTags] = useState(true);
	const [tags, setTags] = useState([]);
	const filterTag = (tag) => {
		navigate(selected === tag.url ? `/blog` : `/blog/tag/${tag.url}`);
	};

	useEffect(() => {
		setLoadingTags(true);
		websiteApi.getBlogTag().then((response) => {
			if (response.data.status === "success") setTags(response.data.result);
			setLoadingTags(false);
		});
	}, []);

	return (
		<div className="blog-tag-box">
			{isLoadingTags ? (
				<Loading message={"Fetching data from server ..."} />
			) : (
				tags.length > 0 &&
				tags.map((value, idx) => (
					<div key={idx} className={"cursor-pointer item " + (selected === value.url ? "active" : "")} onClick={(_) => filterTag(value)}>
						{value.nama}
					</div>
				))
			)}
		</div>
	);
}

export default BlogTag;
