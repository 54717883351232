import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import imgToTheMoon1 from "./assets/img/to-the-moon-1.png";
import imgToTheMoon2 from "./assets/img/to-the-moon-2.png";
import Home from "./routes/Home";
import Blog from "./routes/Blog";
import Article from "./routes/Article";
import Bot from "./routes/Bot";
import Verify from "./routes/Verify";
import NotFound from "./routes/_NotFound";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Announcement from "./components/Announcement";
import usePopup from "./hooks/usePopup";
import "./assets/css/style.css";

function App() {
	const popup = usePopup();
	const [moon, setMoon] = useState(true);
	const [showMoon, setShowMoon] = useState(true);
	const toTheMoon = () => {
		setMoon(false);
		window.scrollTo(0, 0);
		document.querySelector(".to-the-moon").animate([{ transform: "translateY(0px)" }, { transform: "translateY(-" + window.innerHeight + "px)" }], { duration: 1000 }).onfinish = () => {
			setMoon(true);
		};
	};
	const hideToTheMoon = (e) => {
		e.stopPropagation();
		localStorage.setItem("hideKomMan", "true");
		setShowMoon(false);
	};

	useEffect(() => {
		const isHide = localStorage.getItem("hideKomMan");
		if (isHide) setShowMoon(false);
	}, []);

	window.onscroll = () => {
		const el = document.querySelector(".to-the-moon-container");
		if (el) window.scrollY > 512 ? el.classList.add("visible") : el.classList.remove("visible");
	};

	return (
		<>
			{popup && <Announcement data={popup} />}
			<CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="blog" element={<Blog />} />
				<Route path="blog/author/:author" element={<Blog />} />
				<Route path="blog/category/:category" element={<Blog />} />
				<Route path="blog/tag/:tag" element={<Blog />} />
				<Route path="blog/:title" element={<Article />} />
				<Route path="bot" element={<Bot />} />
				<Route path="verify/" element={<Verify />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
			<Footer />
			{showMoon && (
				<div className="to-the-moon-container fixed bottom-1 right-1">
					{moon && (
						<div className="absolute right-4 -top-4 text-xl z-50 cursor-pointer" onClick={(e) => hideToTheMoon(e)}>
							&times;
						</div>
					)}
					<img src={moon ? imgToTheMoon1 : imgToTheMoon2} alt="to-the-moon" className="to-the-moon cursor-pointer" loading="lazy" onClick={toTheMoon} />
				</div>
			)}
		</>
	);
}

export default App;
