import axios from "axios";

const baseUrl = "https://api.kommunitas.net/v1/website/";
const headerToken = "0xC004e2318722EA2b15499D6375905d75Ee5390B8";
const header = {
	headers: { "X-KOM-TOKEN": headerToken },
};

const getFeature = async () => {
	return await axios.get(baseUrl + "feature", header);
};

const getTreasury = async () => {
	return await axios.get(baseUrl + "treasury", header);
};

const getComparison = async () => {
	return await axios.get(baseUrl + "comparison", header);
};

const getStatistic = async () => {
	return await axios.get(baseUrl + "statistic", header);
};

const getEvents = async () => {
	return await axios.get(baseUrl + "event", header);
};

const getPartnerships = async () => {
	return await axios.get(baseUrl + "partnership", header);
};

const getPortfolios = async () => {
	return await axios.get(baseUrl + "portfolio", header);
};

const getExchanges = async () => {
	return await axios.get(baseUrl + "exchange", header);
};

const getCommunityPartnerships = async () => {
	return await axios.get(baseUrl + "communitypartnership", header);
};

const getNewsMedias = async () => {
	return await axios.get(baseUrl + "newsmedia", header);
};

const getAwardRecognitions = async () => {
	return await axios.get(baseUrl + "awardrecognition", header);
};

const getTestimony = async () => {
	return await axios.get(baseUrl + "testimony", header);
};

const setSubscribe = async (email) => {
	let payload = new FormData();
	payload.append("email", email);
	return await axios.post(baseUrl + "subscribe", payload, header);
};

const getBlogCategory = async () => {
	return await axios.get(baseUrl + "blogcategory", header);
};

const getBlogTag = async () => {
	return await axios.get(baseUrl + "blogtag", header);
};

const getBlog = async (params) => {
	try {
		return await axios.get(baseUrl + "blog", {
			headers: { "X-KOM-TOKEN": headerToken },
			params,
		});
	} catch (e) {
		return null;
	}
};

const getPopup = async (page) => {
	return await axios.get(baseUrl + "popup/?page=" + page, {
		headers: { "X-KOM-TOKEN": headerToken },
	});
};

const getVerifyTeam = async (params) => {
	return await axios.get(baseUrl + "verify/", {
		headers: { "X-KOM-TOKEN": headerToken },
		params,
	});
};

const websiteApi = {
	getFeature,
	getTreasury,
	getComparison,
	getStatistic,
	getEvents,
	getPartnerships,
	getPortfolios,
	getExchanges,
	getCommunityPartnerships,
	getNewsMedias,
	getAwardRecognitions,
	getTestimony,
	setSubscribe,
	getBlogCategory,
	getBlogTag,
	getBlog,
	getPopup,
	getVerifyTeam,
};

export default websiteApi;
