import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import websiteApi from "../helpers/ApiHelper";
import BlogCategory from "../components/BlogCategory";

function Article() {
	const { title } = useParams();
	const [article, setArticle] = useState({});

	useEffect(() => {
		websiteApi.getBlog({ title }).then((response) => {
			if (response.data.status === "success") setArticle(response.data.result);
		});
	}, []);

	useEffect(() => {
		if (!!article?.judul) {
			const regex = /.*?(\.)(?=\s[A-Z])/;
			let desc = regex.exec(article?.intro);
			desc = desc !== null ? desc[0] : article?.intro;
			document.title = article?.judul;
			document.querySelector('meta[name="description"]').setAttribute("content", desc);
		}
		const canonicalLink = document.querySelector("link[rel='canonical']") || document.createElement("link");
		canonicalLink.rel = "canonical";
		canonicalLink.href = window.location.href;
		if (!document.querySelector("link[rel='canonical']")) {
			document.head.appendChild(canonicalLink);
		}
	}, [article]);

	return (
		<main>
			<section className="section-bg min-h-screen">
				<div className="container mx-auto py-12 lg:py-24">
					<BlogCategory selected={article?.category} />
					{Object.keys(article).length !== 0 && (
						<div className="blog-container mx-auto">
							<h1 className="mb-2 text-xl font-bold md:text-4xl">{article.judul}</h1>
							<p className="mb-4 text-sm">
								<span className="pr-1">by</span>
								<span className="pr-1">
									<Link to={`/blog/author/${article.author}`} rel="author">
										{article?.aut}
									</Link>
								</span>
								<span className="pr-1">{" | " + article?.tanggal + " | "}</span>
								<span className="pr-1">
									<Link to={`/blog/category/${article.category}`}>{article?.ctg}</Link>
								</span>
							</p>
							<img src={article.img} alt={article.judul} className="mb-2 mx-auto" loading="lazy" />
							<div className="text-justify blog-content">{article.isi && parse(article.isi)}</div>
						</div>
					)}
				</div>
			</section>
		</main>
	);
}

export default Article;
