import React, { useState } from "react";
import imgLogo from "../assets/img/logo.png";
import { SVGIcon } from "../helpers/SVGHelper";
import weblinkData from "../constants/static/weblink.json";
import { Link } from "react-router-dom";

function Header() {
	const [openNavbar, setOpenNavbar] = useState(false);
	const [openSitemap, setOpenSitemap] = useState(false);

	return (
		<header className="sticky top-0 z-50 w-full">
			<div className="w-full p-1 bg-white text-black text-center text-sm">
				Please always double check that the site is <span className="baumans">https://kommunitas.net</span> - bookmark it to be safe
			</div>
			<nav className="container mx-auto py-4 flex flex-wrap items-center justify-between">
				<Link to="/">
					<div className="flex items-center text-xl mr-0 md:mr-12">
						<img src={imgLogo} className="mr-2 md:mr-4" loading="lazy" alt="logo" />
						<span className="baumans">Kommunitas</span>
					</div>
				</Link>
				<div className="w-full flex-grow hidden lg:flex lg:items-center lg:w-auto">
					{weblinkData.header.map((val, idx) => (
						<a key={idx} href={val.link} className={"block mr-6 lg:inline-block " + (val.blink && " font-bold")} target="_blank" rel="noreferrer">
							{val.name}
						</a>
					))}
				</div>
				<div className="ml-auto">
					<button className="inline mx-1 px-3 py-2 border rounded hover:text-white hover:border-white" aria-label="SitemapBtn" onClick={() => setOpenSitemap(!openSitemap)}>
						<div className="fill-current h-4 w-4">{SVGIcon("iconsitemap")}</div>
					</button>
					<button className="inline mx-1 px-3 py-2 border rounded hover:text-white hover:border-white lg:hidden" aria-label="BurgerBtn" onClick={() => setOpenNavbar(!openNavbar)}>
						<div className="fill-current h-4 w-4">{SVGIcon("iconburger")}</div>
					</button>
				</div>
			</nav>
			<div className={"navbar " + (openNavbar ? "visible" : "")} onClick={() => setOpenNavbar(!openNavbar)}>
				<ul onClick={(e) => e.stopPropagation()}>
					<li>
						<div className="mb-4 flex items-center text-xl">
							<img src={imgLogo} className="mr-2" loading="lazy" alt="logo" />
							<span className="baumans text-black">Kommunitas</span>
						</div>
					</li>
					{weblinkData.header.map((val, idx) => (
						<li key={idx}>
							<a href={val.link} className={"mr-6 text-black " + (val.blink && " font-bold")} target="_blank" rel="noreferrer">
								{val.name}
							</a>
						</li>
					))}
				</ul>
			</div>
			<div className={"w-full sitemap " + (openSitemap ? "visible" : "")}>
				<h3 className="mb-2 text-xl text-center font-bold">Website Sitemap</h3>
				<nav className="container mx-auto text-center">
					{weblinkData.sitemap.map((val, idx) => (
						<a key={idx} href={`#${val.id}`} className="button" onClick={() => setOpenSitemap(!openSitemap)}>
							{val.name}
						</a>
					))}
				</nav>
			</div>
		</header>
	);
}

export default Header;
