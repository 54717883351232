import { useState, useEffect } from "react";
import websiteApi from "../helpers/ApiHelper";

export default function usePopup() {
	const [popup, setPopup] = useState(null);

	useEffect(() => {
		const pageURL = window.location.href;
		websiteApi.getPopup(pageURL).then((res) => {
			if (res.data.status === "success") setPopup(res.data.result);
		});
	}, []);

	return popup;
}
