import React from 'react'

export default function Bot() {
    return (
        <main className='container mx-auto my-16'>
            <div className="grid grid-cols-2 gap-x-5">
                <div className="button">
                    <div className="flex items-center justify-between">
                        <h3 className="mr-8 font-bold">
                            Private Partners Bot
                        </h3>
                        <img src="https://kommunitas.net/assets/feature/polygon.svg" className="ml-2" alt="matic" loading="lazy" />
                    </div>
                    <p className="my-4">
                        Stake at least 500,000 KOM and join our Private Partners Group for Private Deals and extra Perks.
                    </p>
                    <a href="https://t.me/komelitebot" className="bg-blue-600 shadow-blue-200 rounded-md px-5 py-2" target="_blank" rel="noreferrer">
                        Run Bot
                    </a>
                </div>
                <div className="button">
                    <div className="flex items-center justify-between">
                        <h3 className="mr-8 font-bold">
                            Staking and Launchpad Bot
                        </h3>
                        <img src="https://kommunitas.net/assets/feature/polygon.svg" className="ml-2" alt="matic" loading="lazy" />
                    </div>
                    <p className="my-4">
                        Stake, Vote, Participate in Launchpad and Claim your vesting token by using this Bot.
                    </p>
                    <a href="https://t.me/kom_tgbot" className="bg-blue-600 shadow-blue-200 rounded-md px-5 py-2" target="_blank" rel="noreferrer">
                        Run Bot
                    </a>
                </div>
            </div>
        </main>
    )
}
