import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link, useParams } from "react-router-dom";
import BlogCategory from "../components/BlogCategory";
import BlogTag from "../components/BlogTag";
import Loading from "../components/Loading";
import websiteApi from "../helpers/ApiHelper";

const Items = ({ currentItems }) => {
	return (
		currentItems &&
		currentItems.map((value, idx) => (
			<div key={idx} className="blog-box">
				<Link key={idx} to={`/blog/${value.url}`}>
					<img src={value.img} alt={value.judul} className="mb-2 mx-auto" loading="lazy" />
					<h2 className="title mb-1 text-xl font-bold">{value.judul}</h2>
				</Link>
				<p className="intro text-justify">{value.intro}</p>
			</div>
		))
	);
};

const PaginatedItems = ({ items, itemsPerPage }) => {
	const [currentItems, setCurrentItems] = useState(null);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);
	const [blogSearch, setBlogSearch] = useState("");
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % items.length;
		setItemOffset(newOffset);
	};

	useEffect(() => {
		let startOffset = itemOffset;
		let endOffset = itemOffset + itemsPerPage;
		let blogList = items;
		if (blogSearch) {
			startOffset = 0;
			endOffset = itemsPerPage;
			blogList = blogList.filter((val) => val.judul.toLowerCase().indexOf(blogSearch.toLowerCase()) > -1 || val.intro.toLowerCase().indexOf(blogSearch.toLowerCase()) > -1);
		}
		setCurrentItems(blogList.slice(itemOffset, endOffset));
		setPageCount(Math.ceil(blogList.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, blogSearch]);

	return (
		<div>
			<input type="text" className="ml-auto mb-4 blog-search" placeholder="Search for blog post" onChange={(e) => setBlogSearch(e.target.value)} />
			<div className="mb-8 grid grid-cols-2 gap-4 md:gap-8">
				<Items currentItems={currentItems} />
			</div>
			<ReactPaginate
				pageCount={pageCount}
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousLabel="«"
				previousClassName="page-item"
				previousLinkClassName="page-link"
				nextLabel="»"
				nextClassName="page-item"
				nextLinkClassName="page-link"
				breakLabel="..."
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName="pagination mt-4"
				activeClassName="active"
				renderOnZeroPageCount={null}
				onPageChange={handlePageClick}
			/>
		</div>
	);
};

function Blog() {
	const { author, category, tag } = useParams();
	const [isLoadingBlog, setLoadingBlog] = useState(true);
	const [blogs, setBlogs] = useState([]);

	useEffect(() => {
		document.title = `Web 3.0 Crowdfunding Ecosystem Platform | Kommunitas`;
		document.querySelector('meta[name="description"]').setAttribute("content", "Kommunitas is a decentralized crowdfunding ecosystem for web3.0 projects.");
		const canonicalLink = document.querySelector("link[rel='canonical']") || document.createElement("link");
		canonicalLink.rel = "canonical";
		canonicalLink.href = window.location.href;
		if (!document.querySelector("link[rel='canonical']")) {
			document.head.appendChild(canonicalLink);
		}
	}, []);

	useEffect(() => {
		setLoadingBlog(true);
		websiteApi.getBlog({ author, category, tag }).then((response) => {
			setBlogs(response?.data?.status === "success" ? response.data.result : []);
			setLoadingBlog(false);
		});
	}, [category, tag]);

	return (
		<main>
			<section className="section-bg">
				<div className="container mx-auto py-12 lg:py-24">
					<BlogCategory selected={category} />
					<div className="px-4 grid grid-cols-12 gap-4 md:gap-x-8">
						<div className="col-span-12 md:col-span-8">
							{isLoadingBlog && <Loading message={"Fetching data from server ..."} />}
							{!isLoadingBlog && !(blogs.length > 0) && <div className="text-xl text-center font-bold">No Articles found</div>}
							{!isLoadingBlog && blogs.length > 0 && <PaginatedItems items={blogs} itemsPerPage={10} />}
						</div>
						<div className="col-span-12 md:col-span-4">
							<div className="mb-4 text-xl font-bold">
								<span className="txt-secondary">$KOM</span> TAGS
							</div>
							<BlogTag selected={tag} />
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}

export default Blog;
