import React from 'react'

function Loading({ message }) {
	return(
		<>
			<div className="text-sm mb-1 italic">{message}</div>
			<div className="loadingbar"></div>
		</>
	)
}

export default Loading;
