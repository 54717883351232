import React, { useEffect, useState } from "react";
import { hashCode } from "../helpers/TextHelper";

function Announcement({ data, show, setShow }) {
	const [openAnnouncement, setOpenAnnouncement] = useState(false);
	const closeAnnouncement = (e) => {
		e.stopPropagation();
		setOpenAnnouncement(false);
		sessionStorage.setItem(`closeAnn${hashCode(window.location.href)}`, "true");
	};
	const clickAnnouncement = (e) => {
		e.stopPropagation();
		if (!!data?.link) window.open(data?.link);
	};

	useEffect(() => {
		const hasRead = sessionStorage.getItem(`closeAnn${hashCode(window.location.href)}`);
		if (hasRead !== "true" && !!data) setOpenAnnouncement(true);
	}, [data]);

	useEffect(() => {
		if (show === true && !!data) {
			setOpenAnnouncement(true);
			setShow(false);
		}
	}, [show]);

	return (
		<div className={"announcement-overlay" + (openAnnouncement ? " open" : " ")} onClick={closeAnnouncement}>
			{!!data?.image && (
				<div
					className="announcement-box"
					onClick={clickAnnouncement}
					style={{
						background: `url('${data?.image}')`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "100%",
						backgroundPosition: "center",
					}}
				>
					<div className="announcement-close" onClick={closeAnnouncement}>
						&times;
					</div>
					{!!data?.link && <div className="announcement-info">*click banner to read more</div>}
				</div>
			)}
		</div>
	);
}

export default Announcement;
