import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
	useEffect(() => {
		document.title = `404 Not Found`;
		document.querySelector('meta[name="description"]').setAttribute("content", "Kommunitas is a decentralized crowdfunding ecosystem for web3.0 projects.");
		const canonicalLink = document.querySelector("link[rel='canonical']") || document.createElement("link");
		canonicalLink.rel = "canonical";
		canonicalLink.href = window.location.href;
		if (!document.querySelector("link[rel='canonical']")) {
			document.head.appendChild(canonicalLink);
		}
	}, []);

	return (
		<main>
			<section className="gradient-1">
				<div className="container mx-auto my-60">
					<div className="max-width-640 mx-auto text-center">
						<h1 className="text-4xl lg:text-6xl">404 Not Found</h1>
						<div className="mt-4 italic">
							<span>Please go back to </span>
							<Link to="/" className="underline">
								home
							</Link>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}

export default NotFound;
