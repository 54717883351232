import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import websiteApi from "../helpers/ApiHelper";

function BlogCategory({ selected }) {
	const [isLoadingCategories, setLoadingCategories] = useState(true);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		setLoadingCategories(true);
		websiteApi.getBlogCategory().then((response) => {
			if (response.data.status === "success") setCategories(response.data.result);
			setLoadingCategories(false);
		});
	}, []);

	return (
		<div className="blog-category-box mb-6 overflow-x-auto">
			{isLoadingCategories ? (
				<Loading message={"Fetching data from server ..."} />
			) : (
				<div className="flex flex-row flex-nowrap">
					<Link to="/blog">
						<div className={"py-2 px-4 whitespace-nowrap " + (typeof selected === "undefined" ? "opacity-50 font-bold" : "")}>All Articles</div>
					</Link>
					{categories.length > 0 &&
						categories.map((value, idx) => (
							<Link key={idx} to={`/blog/category/${value.url}`}>
								<div className={"py-2 px-4 whitespace-nowrap " + (selected === value.url ? "opacity-50 font-bold" : "")}>{value.nama}</div>
							</Link>
						))}
				</div>
			)}
		</div>
	);
}

export default BlogCategory;
